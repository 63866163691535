import React from "react";
import E2 from "../../assets/images/E2.png";
import E3 from "../../assets/images/E3.png";
import E4 from "../../assets/images/E4.png";
import E5 from "../../assets/images/E5.png";
import E6 from "../../assets/images/E6.png";
import E7 from "../../assets/images/E7.png";
import E8 from "../../assets/images/E8.png";
import SetTitle from "../../components/SetTitle";
import styles from "./EarlyChildrenEducation.module.scss";

const EarlyChildrenEducation = () => {
	SetTitle("Early Children Education");

	return (
		<div className={styles.EarlyChildrenEducation}>
			<h1>Early Childhood Education </h1>
			{/* <div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>A Learning Environment</h2>
					<span></span>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas
						tristique tincidunt cras. Vestibulum tincidunt sit Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
						mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras. Vestibulumem ipsum dolor sit amet,
						consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo. Sed maecenas tristique tincidunt cras. Vestibulum
						tincidunt sit tincidunt sit ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis ut curabitur curabitur leo.
						Sed maecenas tristique{" "}
					</p>
				</div>
				<div className={styles.Right}>
					<img src={E1} alt="" />
				</div>
			</div> */}

			<div className={styles.FullContainer}>
				{/* <img src={E9} alt="" /> */}
				<h2>A LEARNING ENVIRONMENT</h2>
				<span></span>
				<p>
					THE TEACHING LEARNING APPROACH AT MINE KIDS PRESCHOOL & CRECHE IS BASED ON THE OBJECTIVES OF THE EARLY CHILDHOOD
					EDUCATION PROGRAMME. ECE IS A CHILD ORIENTED PROGRAMME THAT FOLLOWS THE ACTIVITY, INTEGRATED OR PLAYWAY APPROACH AND
					AIMS AT PROVIDING A STIMULATING ENVIRONMENT FOR INTELLECTUAL, LANGUAGE, SOCIAL, EMOTIONAL AND PHYSICAL DEVELOPMENT OF
					THE CHILD. IT PROVIDES SCHOOL-READINESS FOR THE CHILD AND PREPARES HIM WITH NECESSARY CONCEPTS AND SKILLS FOR LATER
					LEARNING OF READING, WRITING AND MATHEMATICS IN PRIMARY GRADES.
				</p>

				<h3>The broad objectives of the ECE programme are:</h3>

				<ul>
					<li> To develop in a child, good physique, adequate muscular coordination and basic motor skills.</li>
					<li>To develop in a child, good health habits and basic skills necessary for adjustment.</li>
					<li>
						To develop social attitudes and manners that encourage healthy group participation. This way their emotional maturity
						is also attained through which they can freely express themselves.
					</li>
					<li>To stimulate intellectual curiosity so as to help him understand the world around him.</li>
				</ul>
			</div>

			<div className={styles.FullContainer}>
				<h3>For imparting Early Childhood Education programme, the following approaches are followed in teaching:</h3>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={E2} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>activity oriented</h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							This approach follows the learning by doing methodology. The key principles of ‘Learning by Doing’ are Involvement,
							Participation and Contribution which certainly have a longer impact on the child’s brain when it comes to
							development. We provide the children real life experiences through special activities that include thinking,
							conversation, movement and creativity.
						</p>
					</div>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Play way method</h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							The playway method is essentially child-oriented and caters to individual needs, interests and abilities. Play is
							second nature to a child. It serves as a natural way for them to explore, express and involve with the world around
							them. Therefore, this approach supports the all-round development of a child. The experiences in this approach also
							make the child an active participant and not a passive recipient in the learning process.
						</p>
						<p>
							The playway method is essentially child-oriented and caters to individual needs, interests and abilities. Play is
							second nature to a child. It serves as a natural way for them to explore, express and involve with the world around
							them. Therefore, this approach supports the all-round development of a child. The experiences in this approach also
							make the child an active participant and not a passive recipient in the learning process.
						</p>
						<ul>
							<li>Simple to Complex</li>
							<li> Familiar to Unfamiliar</li>
							<li>Concrete to Abstract</li>
						</ul>
						<p>
							<b>For Example:</b> To learn about the fruit apple, they first see, touch and taste it. Then understand it through a
							3D picture of it and finally move on to learning the world ‘Apple’ or reciting a story about it.
						</p>
					</div>
				</div>
				<div className={styles.Right}>
					<img src={E3} alt="" />
				</div>
			</div>

			<div className={styles.FullContainer}>
				<h3>The approach and methodology is practiced for activities in the following domains of development:</h3>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={E8} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>PHYSICAL & MOTOR DEVELOPMENT</h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							The childhood should be no less than a carnival of good memories, love and enjoyment and we, at Mine Kids ensure
							they get ample opportunities to run, play and simply be themselves! Natural growth occurs best when children have
							lots of fun. And, all that fun happens at our splash pool, rain dance, doll house and the most colourful play area.
						</p>
						<h4>Key areas in Physical & Motor Development:</h4>
						<ul>
							<li>
								<b>Proper Growth (Monitoring Height & Weight of Children)</b>
								<br /> To make them stronger & sharper, a balanced diet is a must. Our in house kitchen helps provide hygienic and
								nutritious baby friendly food which is tasted by the incharges first, then served to the little ones.
							</li>
							<li>
								<b>Development of Gross Motor Skills</b>
								<br />
								Gross motor skills mean developing control over the movements of the large muscles of the body. Activities like
								walking, running, swinging dancing, etc ensure that the students can get a good control over their body. While,
								they enhance their flexibility during the peaceful yoga time. We also organise an annual Sports Day wherein our
								budding sportsmen participate in different competitions which include racing, rabbit jump, etc.
							</li>
							<li>
								<b>Development of Fine Motor Skills</b>
								<br />
								Fine motor skills mean developing control over the movements of finer muscles, particularly finger muscles and
								hand-eye coordination. Activities like art & craft which include drawing, colouring, pasting as well as paper
								folding, thumb printing, pattern making, clay moulding and beading ensure a control over their fingers and also
								attain a perfect hand-eye coordination in a fun way
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>INTELLECTUAL & COGNITIVE DEVELOPMENT</h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							The IQ of a child just doesn't depend on the genes alone, but the opportunities he/she gets to use his mind.
							Children between the age of 2.5 to 5 years are at a stage of cognitive development when their thinking is governed
							by their perception and they learn through first hand experience of the environment. Children at Mine Kids are given
							opportunities to learn how to be analytical so that they can successfully deal with life situations.
						</p>
						<h4>Key areas in Intellectual & Cognitive Development:</h4>
						<ul>
							<li>
								<b>Sensory Development </b> <br />
								The development of the five senses, i.e, seeing, hearing, touch, smell and taste is basic to the process of
								knowing and understanding. The more varied and extensive the sensory experiences, the broader will be the child’s
								base for forming ideas. Our sound boxes, touch & sniff boxes, audio-visual aids, smell tray and tasting kits help
								the children get a grip on all five senses with ease.
							</li>
							<li>
								<b>Problem Solving and Reasoning</b>
								<br /> Who doesn’t love puzzles and block towers? At our intellectual development centre, the children learn
								through an integrated approach with our self designed teaching tools. They are encouraged to find solutions to
								diverse challenges such as solving picture puzzles, building a seriation tower using their own logic, learning
								tens and units of mathematics through a ‘Ganit Mala’, etc.
							</li>
							<li>
								<b>Development of Basic Concepts</b>
								<br /> For holistic development, it is crucial that different concepts are clear to the students. They should be
								able to observe, discriminate and categorise different objects in their environment and progress from ‘perception
								bound reasoning’ to ‘logical reasoning’. To achieve this, we host specials days that introduce a particular
								concept to the students, such as, ‘Colour Blue Day’, ‘My Favourite Cartoon Day’, ‘Introduction to shape Triangle
								day’ or ‘Introduction to numbers’ as a part of development of prenumber concept. Different activities are
								conducted on these days to help them match, identify, name, compare and classify the objects. We also follow a
								monthly theme which are an amalgamation of all these concepts.
							</li>
						</ul>
					</div>
				</div>
				<div className={styles.Right}>
					<img src={E4} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={E6} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>LANGUAGE DEVELOPMENT</h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							What good is knowledge that cannot be expressed! Language learning provides the foundation for all later learning.
							By the time children enter pre-school, they are already using a language at home. Mine Kids gives them the right
							experiences and environment to increase their vocabulary steadily and rapidly.
						</p>
						<h4>Key areas in Language Development:</h4>
						<ul>
							<li>
								<b>Development of Listening Skills</b>
								<br /> We organise a circle time as a part of our daily schedule as well as conduct story-telling sessions and
								show the students, cartoon movies to develop their listening skills. During these sessions they also learn sound
								discrimination such as ‘voices of different animals’ or the sound of clapping, etc. To help them develop an ear
								for phonics, we conduct many activities using our our phonic card box.
							</li>
							<li>
								<b>Development of Vocabulary related of Body, Home and Environment</b> <br />
								The children are indulged in fun conversations and action rhymes to introduce them with the vocabulary related to
								their body, home and environment. Picture reading cards, role play and dramatization are also used as a medium to
								enhance their vocabulary when it comes to the socio-emotional scenario.
							</li>
							<li>
								<b>Development of Oral Expression </b> <br />
								Free conversation is the first activity after welcome and arrival activities. Children have a lot to express about
								what happened at their homes after they left school the day before. They are given absolute freedom to share their
								experiences and talk about anything they want. This way they learn to create sentences and use correct grammar.
								Show and tell is yet another activity which enhances their oral expression.
							</li>
							<li>
								<b>Development of Reading Readiness</b>
								<br /> Here is when our specially designed curriculum books come into picture. Through the visuals in the books,
								the children learn to identify objects as well as situations. Special introduction to letters activities are also
								conducted so that they can gradually identify letters and words mentioned in the book.
							</li>
							<li>
								<b>Development of Writing Skills</b>
								<br /> Before children write, they need experience in handling crayons and pencils. Thus, initially, they are
								indulged in drawing and colouring activities wherein they develop the skills of making controlled visual motor
								movements. Later on, they move on to joining dots, tracing, copying forms and making patterns similar to the
								letters of the alphabet.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>SOCIO – EMOTIONAL DEVELOPMENT</h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							Each child has his/her own unique ‘Personality’ which is the outcome of inheritance, social/cultural groups,
							different sorts of interactions, regular appreciation and exposure. Mine Kids provides the right kind of peer group
							which forms the learning ground for the child about right behaviour, friendship, sharing.
						</p>
						<h4>Key areas in Socio-Emotional Development:</h4>
						<ul>
							<li>
								<b> Development of Positive Self Concept and Qualities of Initiative Independence and Leadership.</b>
								<br /> This is achieved by creating a sense of individuality in them through name cards or even celebrating
								birthdays in school. Qualities of leadership are gained when they are given responsibilities such as maintaining a
								straight line. In group activities, they also take on the lead role of reciting a story or while playing a game.
							</li>
							<li>
								<b>Development of Good Personal Habits</b>
								<br /> We regularly conduct story-telling sessions and puppet play that carry relevant messages of good habits.
								Picture cards of good and bad habits are also used to educate the children. As a part of the routine, they are
								asked to wash their hands before meals, visit the toilet during breaks, organise play material back in the racks
								after playing, etc.
							</li>
							<li>
								<b>Development of the Self-Confidence</b>
								<br /> As a part of the confidence building process, we organise many activities and events such as recitation
								competitions, fancy dress, fashion show, role play, class presentations and appreciation day. When they get an
								opportunity to perform in front of an audience, it automatically increases their confidence level.
							</li>
							<li>
								<b> Development of ability to respect the feelings of others and relate with adults</b>
								<br /> During the circle time, the students are indulge in interactions with their peers and teachers. They get a
								chance to freely express themselves, listen to others and exchange opinions. Their inquisitive minds are also
								addressed as the teachers are always willing to provide them with all answers and listen to their queries
								patiently. Lunch time is also a great way to encourage bonding between the peers as they learn to share their
								meals. We also believe that children’s curiosity and interests are stimulated when they interact with people,
								places, communities and the environment around us. To achieve this, we take them for picnics and excursions.
							</li>
							<li>
								<b> Development of attitude of care towards the environment </b>
								<br />
								Our aim at Mine Kids is to create responsible global citizens. To become so, they need to be environmentally
								conscious as well. We believe that building a connect with nature at this age will go a long way in ensuring a
								sustainable environment. Thus, they often go for nature walks where they learn about the nature and also
								participate in tree plantation drives.
							</li>
						</ul>
					</div>
				</div>
				<div className={styles.Right}>
					<img src={E7} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={E5} alt="" />
				</div>
				<div className={styles.Right}>
					<h2> DEVELOPMENT OF CREATIVE EXPRESSION & AESTHETIC APPRECIATION </h2>
					<span></span>
					<div className={styles.Text}>
						<p>
							Each child has inborn creativity. All we need to do is give them encouragement and opportunities for free play,
							particularly dramatic and constructive play which will foster creativity in them. We provide children with a
							stimulating environment where they can discover their talents like dancing, art and craft, music, clay moulding etc.
							Aesthetic Appreciation is a form of development wherein children are encouraged to appreciate their surroundings and
							also verbalise their appreciation about whatever they like around them. This way a positive energy can be
							channelised in them, which can help them in their future endeavours.
						</p>
						<h4>Key areas in Development of Creative Expression:</h4>
						<ul>
							<li>
								<b>Creative Expression through Art </b>
								<br />
								‘In every work of art, the artist himself is present’. Art is one of the best ways to explore the inborn
								creativity of the little minds. We organise many art and craft activities for the students wherein they bring out
								their most creative sides. A few of them include: Drawing, Colouring, Paper Folding and Clay Moulding.
							</li>
							<li>
								<b>Creative Movement </b>
								<br /> To let the children feel free to express through body movements and expressions, they are indulged in dance
								& music sessions. Rhythmic movement such as foot stamping, finger clicking, etc, also help the children loosen up
								and become more free with their movements.
							</li>
							<li>
								<b>Creative Thinking </b>
								<br />
								Every child is a dreamer with his/her own world full of imaginations and wonder. We know the value of these
								imaginations as it is one of the best ways to enhance creative development. During free play, in the Doll house,
								the little ones create their own stories as to how they want their life to be or how to address a particular
								situation they are put in. They effectively learning about the 5Ws and 1H through diverse fun activities.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className={styles.FullContainer}>
				<h3>
					Each day is planned so as to reflect a balance of individual and group activities, outdoor and indoor activities, active
					and quiet activities, free and structured / guided activities and activities to foster all aspects of development. On
					the whole, our aim at Mine Kids, is to blossom a child's personality by making the most of the formative years in an
					ideal learning environment.
				</h3>
			</div>
		</div>
	);
};

export default EarlyChildrenEducation;
