import React from "react";
import SetTitle from "../../components/SetTitle";
import styles from "./Message.module.scss";

const Message = () => {
	SetTitle("Message");

	return (
		<div className={styles.Message}>
			<div className={styles.Container}>
				<h1>Principal Message</h1>
				<span></span>
				<h2>Welcome to Mine Kids Preschool & Creche</h2>
				<p>
					As the principal of this wonderful institution, it is my pleasure to extend a warm greeting to all of you. Our play
					school is a place where young minds are nurtured, creativity is encouraged, and learning is made joyful. We are
					committed to providing a safe, stimulating, and loving environment where every child can thrive.
				</p>
				<p>
					At Mine Kids Preschool & Creche, our mission is to create a foundation for lifelong learning and personal growth. We
					believe that early childhood is a critical period for development, and our goal is to foster a love for learning through
					a balanced curriculum that combines academic excellence, physical activity, and creative expression.{" "}
				</p>
				<p>
					Our Commitment to Your Child: Safe and Nurturing Environment, Holistic Development, Individual Attention, Parental
					Involvement
				</p>
				<p>
					Our curriculum includes a variety of programs and activities designed to enrich your child's learning experience:
					Interactive Learning, Creative Arts, Physical Activities, Special Events and many more…
				</p>
				<p>
					Thank you for entrusting us with your child's early education. We look forward to a year filled with discovery, growth,
					and joy. Together, we can make Mine Kids Preschool & Creche a place where every child feels valued, supported, and
					inspired to learn.
				</p>
			</div>
			<div className={styles.Container}>
				<h1>Director's Message</h1>
				<span></span>
				<h2>Welcome to Mine Kids Preschool & Creche</h2>
				<p>
					As the Director of this nurturing and dynamic institution, it gives me great pleasure to extend a heartfelt welcome to
					all of our families. At Mine Kids Preschool & Creche, we are dedicated to creating a warm, engaging, and educational
					environment where your children can flourish during these crucial early years.
				</p>
				<p>
					We believe that early childhood is a time of incredible growth and exploration. Our philosophy is rooted in the
					understanding that each child is unique and deserves a personalized approach to their education. Our goal is to foster a
					love of learning, creativity, and curiosity that will serve as a strong foundation for future academic and personal
					success.
				</p>
				<p>Commitment to Excellence: Safety and Well-Being, Holistic Development, Experienced Educators, Engaging Curriculum.</p>
				<p>
					We see parents as our most important partners in the educational journey. Your involvement and feedback are vital to us.
					We aim to build a strong relationship with you through: Regular Communication, Open Door Policy, Parent Workshops and
					Events.
				</p>
				<p>
					As we embark on this new school year, I am filled with excitement and optimism for the adventures ahead. Together with
					our talented staff, we are committed to providing an exceptional early childhood education experience for your child.
				</p>
				<p>
					Thank you for choosing Mine Kids Preschool & Creche. We look forward to working closely with you to ensure that your
					child's time with us is filled with growth, joy, and discovery. Together, we can create a bright and promising future
					for our children.
				</p>
			</div>
		</div>
	);
};

export default Message;
