import React from "react";
import OS from "../../assets/images/OS.png";
import styles from "./OurSchool.module.scss";

const OurSchool = () => {
	return (
		<div className={styles.OurSchool}>
			<img src={OS} alt="" />
			<h1>welcome to our school</h1>
			<span></span>
			<p>
				It is a known fact that children do their most important learning before the age of five. At this age, for kids each
				morning is the dawn of another amazing adventure. Considering this fact, our focus is not only on developing academic
				skills, but also intellectual, emotional, linguistic, physical, social and moral skills that will ensure all-round
				development of children.{" "}
			</p>

			<p>
				We believe that children are active learners, who learn best from interacting with nature, other children and adults in
				child-centered activities.{" "}
			</p>

			<p>
				Mine Kids provides caring and trusting environment in which children can flourish as individual. Our child centered
				philosophy allows children to learn through play by exploring their environment. In order to stimulate a child's learning,
				we provide opportunities to each child to grow and develop while reading, listening and playing. Love and affection are
				the hallmarks of all these pursuits.
			</p>

			<p>
				The colorful and cheerful environment, child-friendly equipment, enticing toys and games at Mine Kids help a child to
				listen and discover, imagine and create. Overall, Mine Kids is not merely a school; it is a concept in education. It is a
				new dimension to a perfect mural for learning and childhood development.
			</p>
		</div>
	);
};

export default OurSchool;
