import React from "react";
import OS from "../../assets/images/OS.png";
import styles from "./OurPurpose.module.scss";

const OurPurpose = () => {
	return (
		<div className={styles.OurPurpose}>
			<img src={OS} alt="" />
			<h1>our purpose</h1>
			<span></span>
			<p>
				Children at Mine Kids are groomed under the Quality Education System designed by the school's Research & Development
				department. This team of professionals plans a scientific curriculum, design innovative, effective teaching methods, and
				explore and create new and better teaching aids.
			</p>
		</div>
	);
};

export default OurPurpose;
