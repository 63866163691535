import React from "react";
import DownArrow from "../../assets/svg/DownArrow.svg";
import styles from "./FaqCard.module.scss";

const FaqCard = ({ data, openFaqIndex, setOpenFaqIndex }) => {
	const toggleFaq = () => {
		setOpenFaqIndex(openFaqIndex === data.id ? null : data.id);
	};

	return (
		<div className={styles.FaqCard}>
			<div className={styles.Upper} onClick={toggleFaq}>
				<h4>{data?.question}</h4>
				{openFaqIndex === data.id ? (
					<img src={DownArrow} alt="" style={{ transform: "rotate(180deg)", transition: "0.3sec ease" }} />
				) : (
					<img src={DownArrow} alt="" />
				)}
			</div>
			<p className={openFaqIndex === data.id ? styles.Active : ""}>{data?.answer}</p>
		</div>
	);
};

export default FaqCard;
