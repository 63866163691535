export const faq = [
	{
		id: 1,
		question:
			"What age group does Minekids BBSR cater to? Are they focused on preschool-aged children exclusively or do they offer programs for older children as well?",
		answer:
			"Minekids Bhubaneswar caters to children from age group 2 years to 8 years for their foundational course as per NEP 2020. Classes followed by Play Group, Nursery, Lower Kindergarten, Upper Kindergarten, STD.I & STD.II. Mine Kids Bhubaneswar also have the facilities of Day Care & Activities Center for the children up to age of 12 years. Mine Kids Bhubaneswar also help the \u201cchildren with special needs\u201d to enroll them as regular kids in the ratio of 20:1.",
	},
	{
		id: 2,
		question:
			"What is the educational philosophy or approach of Minekids BBSR? Are they Montessori-based, play-based, or follow a different curriculum?",
		answer: `Mine Kids Bhubaneswar follow both Montessori and Kindergarten methodology.
			
			As the Montessori methodology is an educational approach developed by Dr.Maria Montessori.It emphasizes independence, freedom within limits, and respect for a child's natural psychological, physical, and social development. It's characterized by mixed- age classrooms, self - directed activity, and hands - on learning materials designed to cultivate critical thinking and creativity.

			Kindergarten methodology refers to the educational approach used in kindergarten classrooms, typically for children around the ages of 4 - 6. It focuses on providing a nurturing environment that promotes social, emotional, cognitive, and physical development through play - based learning, structured activities, and guided exploration.Key components often include building social skills, early literacy and numeracy development, and fostering creativity and curiosity.`,
	},
	{
		id: 3,
		question: "What are the main features or facilities of Minekids BBSR? Do they offer any unique programs or services?",
		answer: `The Core Values of Mine Kids Bhubaneswar are Integrity, Transparency and Trust.

         A. Features of Mine Kids Bhubaneswar:

         1. Play-based learning: Emphasis on activities that promote exploration, creativity, and hands-on experiences rather than traditional academic instruction.
         2. Safe and nurturing environment: Creating a space where children feel secure to explore and express themselves freely.
         3. Stimulating materials and activities: Providing age-appropriate toys, games, and learning materials that encourage cognitive, physical, and social development.
         4. Qualified and caring staff: Teachers trained in early childhood education who understand child development and know how to support each child's individual needs.
         5. Socialization opportunities: Structured and unstructured activities that foster social skills, cooperation, and friendship building among peers.
         6. Parent involvement: Opportunities for parents to engage with their child's learning process through communication, observation, and participation in school activities.
         7. Preparation for formal education: Introducing basic concepts in literacy, numeracy, and other foundational skills in a fun and engaging manner to prepare children for kindergarten and beyond.
		 
		 B. Unique programs organised by Mine Kids Bhubaneswar:

         1. "Nature Explorer" Program: This program focuses on fostering a love for nature and environmental awareness in young children. It includes activities such as outdoor nature walks, Park, gardening, bird watching, visit and learning about different plants and animals.
         2. “Camps” Seasonal camps are organised to keep children engaged and excited about learning throughout the year. *Summer Adventure Camp*: We offer a summer camp filled with outdoor activities, water play, nature walks, and many more fun. *Winter Wonderland Camp*: We create a winter-themed camp with activities like snowflake crafts, indoor activities, Christmas Celebration and many more.
         3. “Field Trips”: It also helps in improving children's social and interactive skills. Taking part in field trips is one of the best ways to make learning fun for children outside the classroom. They rather they also help in allowing them to learn, absorb and experience things practically. Field trip such as visit to Science Museum, Regional Museum, Super Market, Bank, Temple, Bus Terminal, Railway Terminal,
         Airport etc.
         4. “Knowledge Sharing Session”: In this session, the primary teachers from various reputed formal schools visit Mine Kids Bhubaneswar and conduct classes while sharing their knowledge, experience with our kids and teachers. Parents also took part in this session where they deliver their best practices to the kids.
         5. “Health is Wealth”: This program is organised by Mine Kids Bhubaneswar for their children where different health check up are been done, such as Eye Check up, Dental Check up, General doctor & dietician visit.`,
	},
	{
		id: 4,
		question: "Where is Minekids BBSR located? Is it situated in a residential area, commercial district, or another location?",
		answer: `Mine Kids Bhubaneswar is operating its 2(two) branches in the Temple city. Both the branches are situated in the urban residential area.

        Branch 1: Mine Kids Preschool & Creche- Baramunda: Model-7, Shanti Vihar, Baramunda Housing Board Colony, Bhubaneswar, Khordha. Pin- 751003.

        Branch 2: Mine Kids Preschool & Creche- Patrapada: Plot No-275, Bhagabanpur, Near Govt. Primary School, Patrapada, Bhubaneswar, Khordha. Pin- 751019. `,
	},
	{
		id: 5,
		question:
			"What sets Minekids BBSR apart from other preschools in the area? Do they have any specializations or areas of expertise?",
		answer: `Area of expertise that makes Mine Kids Bhubaneswar different from other schools are as follows.

I. Affiliation and Accreditation

1. ISO 9001-2015 certified Play School & Day Care in Bhubaneswar.
2. Running under the banner of Ekteswar Solutions LLP & Nyradeep Foundation Trust.
3. Life Member of Early Childhood Association.
4. MSME & Udyog Aadhar Registered School.
5. Received Trade License under Bhubaneswar Municipal Corporation.

II. Innovation & Experience

1. Directors with more than 16 yrs of experience in the field of early Childhood Development.
2. Long Term Association Winner Teacher’s & Staffs for more than 5 years and above.
3. Having own Montessori & Nursery Teacher’s Training Center running across pan India.
4. Organised in many Leadership and Quality Management Workshop.
5. A Good Team Player.

III. Education & Value
1. Nature Friendly environment for the children.
2. “Impact of C3” i.e. Children Creative Curriculum is been used to make the learning simple and interesting.
3. Making Transparency with parents helps to develop the believe system, which help us to improve student retention.`,
	},
	{
		id: 6,
		question: "How long has Minekids BBSR been in operation? Have they received any awards or recognition for their services?",
		answer: `Mine Kids Bhubaneswar starts its first journey of Mine Kids Baramunda on 22nd January 2018 (Monday) on the auspicious day of Basant Panchami with the blessing of Maa Saraswati. Mine kids Bhubaneswar established its 2nd Branch of Mine Kids Patrapada on 19th September 2023 (Tuesday) on the auspicious day of Ganesh Chaturthi with the blessing of Lord Ganesha. During the Journey, the school had been awarded with Krishna Acharya Samman & Sarathi Award.`,
	},
	{
		id: 7,
		question:
			"What is the typical schedule or daily routine for children attending Minekids BBSR? Do they offer full-day, half-day, or flexible scheduling options?",
		answer: `Schedule for Mine Kids Bhubaneswar children to attend School are as follow.
Play Group & Nursery: 9.00 am to 12.00 noon
L.KG to Std II: 9.00 am to 1.00 pm.
And for Day Care student timings are from 9.00 am to 7.00 pm`,
	},
	{
		id: 8,
		question: "Does Minekids BBSR have any specific values or principles that guide their approach to early childhood education?",
		answer: `Mine Kids Bhubaneswar values that guide the early childhood education involves instilling a range of values that promote holistic development and lifelong learning.

1. *Respect*: Teach children to respect themselves, others, and the environment. Encourage kindness, empathy, and consideration for others' feelings and perspectives.

2. *Curiosity*: Nurture children's natural curiosity and encourage them to ask questions, explore, and discover the world around them. Foster a love for learning and a sense of wonder.

3. *Creativity*: Value and celebrate children's creativity and imagination. Provide opportunities for artistic expression, open-ended play, and problem-solving activities that encourage innovative thinking.

4. *Independence*: Foster children's independence by providing opportunities for them to make choices, solve problems, and take initiative in their learning. Encourage autonomy and self-help skills.

5. *Resilience*: Teach children to cope with challenges, setbacks, and failures in a positive and resilient manner. Encourage perseverance, adaptability, and a growth mindset.

6. *Collaboration*: Emphasize the importance of collaboration and teamwork. Provide opportunities for children to work together, communicate effectively, and learn from each other's strengths and differences.

7. *Critical Thinking*: Encourage children to think critically, question, analyze, and evaluate information. Provide opportunities for problem-solving, decisionmaking, and reasoning activities.

8. *Responsibility*: Help children develop a sense of responsibility for themselves, their actions, and their environment. Encourage them to take care of their belongings, follow rules, and contribute to the community.

9. *Inclusivity*: Promote inclusivity and diversity by creating an environment where all children feel valued, respected, and included. Celebrate cultural differences and teach children to appreciate and respect others' backgrounds and perspectives.

10. *Environmental Stewardship*: Instill values of environmental awareness and responsibility by teaching children to care for and respect the natural world. Encourage sustainable practices and outdoor exploration.

11. *Communication*: Foster effective communication skills by encouraging children to express themselves verbally, non-verbally, and through various forms of expression such as art, music, and storytelling.

12. *Joy of Learning*: Cultivate a love for learning by making education enjoyable, engaging, and meaningful. Create a positive and supportive learning environment where children feel motivated and excited to explore and discover new things.`,
	},
	{
		id: 9,
		question: "Does Minekids BBSR have an online presence or social media accounts? If so, which platforms are they active on?",
		answer: "Mine Kids Bhubaneswar has its present in Facebook among its parents where we update the celebration photographs.",
	},
	{
		id: 10,
		question:
			"What are the primary goals or objectives for Minekids BBSR's Instagram content? (e.g., showcasing activities, engaging with parents, promoting enrollment)",
		answer:
			"Primary goals of Mine Kids Bhubaneswar in Instagram Content is to create Brand awareness, Showing activities of the children, Engaging with parents & Promoting Enrollments.",
	},
];
