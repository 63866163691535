import React from "react";
import SetTitle from "../../components/SetTitle";
import L1 from "../../assets/images/L1.png";
import L2 from "../../assets/images/L2.png";
import L3 from "../../assets/images/L3.png";
import L4 from "../../assets/images/L4.png";
import L5 from "../../assets/images/L5.png";
import styles from "./LevelOfLearning.module.scss";

const LevelOfLearning = () => {
	SetTitle("Level of Learning");

	return (
		<div className={styles.LevelOfLearning}>
			<h1>Level of Learning</h1>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>settling batch</h2>
					<span></span>

					<div className={styles.Text}>
						<p>
							Learning to be away from family is the toughest transition for a young child. Going to preschool will be the first
							time they will be away from their parents for so long. While it is exciting for a few parents, it is also a nerve
							wrecking situation for many. To make this change easier, we have a specially designed settling zone where we
							organize interesting activities to keep the atmosphere upbeat for little children. We aim to create an environment
							that helps in building relationships between the children and teachers to ensure positive outcomes in all areas of
							learning and growth. It is crucial for them to feel safe, stimulated and happy just like they do at home. This zone
							also builds the trust of the parents in the preschool and how they be be partners with us for the perfect upbringing
							of their little one. The toddlers are welcomed and involved from the very beginning:
						</p>

						<ul>
							<li>
								Besides other facilities certain special facilities offered in the settling zone are: Play Zone, Creativity Corner
								(vegetable printing, thumb printing, etc), Clay Moulding and Cartoon Movie Shows.
							</li>
							<li>
								A teacher is allocated to each child and his/her family before he/she starts to attend the school. The teacher
								ensures that every childâ€™s care is tailored to meet their individual needs so that they can settle into the
								preschool atmosphere with ease.
							</li>
							<li>
								The parents are welcome to visit the school along with their child till he/she becomes comfortable in the presence
								of other care giving adults.
							</li>
							<li>
								The teachers share information on a regular with the parents about their childâ€™s developmental records and how
								well he/she is doing in school.
							</li>
						</ul>
					</div>
				</div>
				<div className={styles.Right}>
					<img src={L1} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={L2} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>play group (1.5 to 2.5 Years )</h2>
					<span></span>
					<h2>Duration: 3 Hours/Day</h2>
					<div className={styles.Text}>
						<p>
							<b>*As per RTE- Right to Education and NEP- National Education Policy.</b>
						</p>
						<p>
							Children in this age group as identified as Toddlers. Children are born curious. They begin to explore the
							environment around them as soon as they are born. Mine Kids playgroup age appropriate curriculum plays an important
							role in helping children continue their exploration.
						</p>
						<p>
							Mine Kids Playgroup prepares a child developmentally and academicaly for regular school (age 3 years and above)
							based on NEP 2020.
						</p>
						<p>
							The first few years of a child’s life are crucial and critical when it comes to the acquisition of skills and brain
							based learning. In the playgroup class, the young learners develop their latent language and math skills which is
							core to successful future learning.
						</p>

						<h3>Key Areas in Playgroup Curriculum</h3>
						<ul>
							<li>Promoting positive peer social interaction</li>
							<li>Exploration based learning</li>
							<li>Language Readiness (Letter Recognition)</li>
							<li>Opportunities for basic motor development</li>
							<li>Math Readiness (Number Recognition)</li>
							<li>Promoting cognitive development (Sorts using one attribute)</li>
						</ul>
					</div>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Nursery (2.5 To 3.5 Years) </h2>
					<span></span>
					<h2>Duration: 3 Hours/Day</h2>
					<div className={styles.Text}>
						<p>
							<b>*As per RTE- Right to Education and NEP- National Education Policy.</b>
						</p>
						<p>
							The child is now identified as a Pre-schooler. (Mandatory level 1 of Foundational Stage as per NEP) To set a firm
							foundation in the early years of preschool, quality education is vital for the overall development of a child. Our
							nursery education has a right array of activities that helps a child move towards achieving their early learning
							goals.
						</p>
						<p>
							Quality education in the early years of preschool lays a strong foundation for the overall development of a child.
							The nursery curriculum engages children in FLN- (Foundation in literacy and Numeracy) school readiness activities
							such as reading, writing, counting, number value, and problem-solving in a fun-filled but purposeful manner.
							Children are encouraged to display their learning through collaborative activities in the areas of dramatics,
							science, and arts.
						</p>

						<h3>Key Areas in Nursery Curriculum</h3>
						<ul>
							<li>Developing communication skills </li>
							<li>Language Skill Development (Phonics Introduction) </li>
							<li>Opportunities for advanced fine and gross motor development</li>
							<li>Learning to expression through art and drama </li>
							<li>Math Skill Development (Number Value)</li>
							<li>Promoting cognitive development (Sorts using two attributes)</li>
						</ul>
					</div>
				</div>
				<div className={styles.Right}>
					<img src={L3} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={L4} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Kindergarten Junior K.G (4 - 5 Years)</h2>
					<span></span>
					<h2>Duration: 4 Hours/Day</h2>
					<div className={styles.Text}>
						<p>
							<b>*As per RTE- Right to Education and NEP- National Education Policy.</b>
						</p>
						<p>
							This is level 2 and 3 of preschool -Foundational Stage 1 - Mandatory as per NEP). This is in progression to the Mine
							Kids Nursery Curriculum. At Mine Kids, the Kindergarten (KG) curriculum ensures a smooth transition to formal
							schooling. Age-appropriate activities in various areas like language, numbers, general knowledge, music, and art are
							conducted in a planned manner throughout the program. Proprietary programs for Phonics and English Readiness makes
							us the one of the best preschools in India. A Child's development is measured thrice in a year using a well-designed
							assessment.
						</p>

						<h3>Key Areas in Kindergarten Curriculum</h3>
						<ul>
							<li>Developing oral and written communication skills</li>
							<li>Math Operations</li>
							<li>Promoting cognitive development (Sorts using multiple attributes) </li>
							<li>English Readiness Programme</li>
							<li>Personal, Social, and Emotional Growth</li>
							<li>Visual & Performance Art</li>
						</ul>
					</div>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Kindergarten Senior K.G.(5 - 6 Years)</h2>
					<span></span>
					<h2>Duration: 4 Hours/Day</h2>
					<div className={styles.Text}>
						<p>
							<b>*As per RTE- Right to Education and NEP- National Education Policy.</b>
						</p>
						<p>
							This is level 2 and 3 of preschool -Foundational Stage 1 - Mandatory as per NEP). This is in progression to the Mine
							Kids Nursery Curriculum. At Mine Kids, the Kindergarten (KG) curriculum ensures a smooth transition to formal
							schooling. Age-appropriate activities in various areas like language, numbers, general knowledge, music, and art are
							conducted in a planned manner throughout the program. Proprietary programs for Phonics and English Readiness makes
							us the one of the best preschools in India. A Child's development is measured thrice in a year using a well-designed
							assessment.
						</p>

						<h3>Key Areas in Kindergarten Curriculum</h3>
						<ul>
							<li>Developing oral and written communication skills</li>
							<li>Math Operations</li>
							<li>Promoting cognitive development (Sorts using multiple attributes) </li>
							<li>English Readiness Programme</li>
							<li>Personal, Social, and Emotional Growth</li>
							<li>Visual & Performance Art</li>
						</ul>
					</div>
				</div>
				<div className={styles.Right}>
					<img src={L5} alt="" />
				</div>
			</div>
		</div>
	);
};

export default LevelOfLearning;
