import React from "react";
import SetTitle from "../../components/SetTitle";
import styles from "./AdmissionFAQ.module.scss";

const AdmissionFAQ = () => {
	SetTitle("Admission FAQ");

	return (
		<div className={styles.AdmissionFAQ}>
			<h1>Why Mine Kids?</h1>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>ADMISSIONS DETAILS AT MINE KIDS PRESCHOOL & CRECHE</h2>
				</div>
				<p>
					FROM THE MOMENT YOUR CHILD STARTS BUILDING SENSES, AS PARENTS, YOU START PONDERING OVER THE DILEMMA OF WHAT IS THE RIGHT
					AGE FOR PRESCHOOL AND WHETHER OR NOT HE NEEDS ONE. MANY STUDIES WORLDWIDE SUGGEST THAT TO DECIDE WHETHER OR NOT HE NEEDS
					A PRESCHOOL, PARENTS FIRST MUST CHECK THEIR BEHAVIOUR IN THE COMFORT ZONE OF THEIR HOUSE. SOME HOUSES ALREADY PROVIDE A
					SCHOOL ENVIRONMENT TO THE CHILDREN, FROM A LITTLE LIBRARY OF STORY BOOKS & TOYS TO A PERFECT SCHEDULE WHICH PREPARES THE
					CHILDREN IN ADVANCE, SUCH THAT THEY ARE COMPLETELY READY FOR PRESCHOOL. ON THE OTHER HAND, THE WORKING PARENTS WHO FIND
					IT DIFFICULT TO SPARE ENOUGH TIME FOR THE CHILD’S DEVELOPMENT DURING THE INITIAL YEARS OF HIS LIFE, FIND A GREAT PARTNER
					IN A PRESCHOOL.
				</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>When?</h2>
				</div>
				<p>
					The ideal age for admitting toddlers in preschool is two years. Most experts call it ‘The Miracle Year’, which truly
					deserves the right environment and exposure. They should get an opportunity to enjoy their childhood to the fullest and
					we, at Mine Kids ensure that everyday, every moment is extremely special for them. A lot of focus is also laid on giving
					a great beginning to their educational journey by making the learning process fun and exciting.
				</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>Why?</h2>
				</div>
				<p>
					From when he opens his eyes to see a bright light, to his first word and his first walk, the initial few years of your
					child’s life are the most crucial. What they are taught during this time stays with them throughout their life. We, at
					Mine Kids believe in setting the perfect foundation for your child by nurturing their minds to develop a positive
					approach towards the world. Popularly known as the trendsetters of preschool education, we have created a healthy
					amalgamation of new age curriculum and a ‘feel at home’ experience where education is imparted for life with utmost
					love. We ensure that children can be their own directors of the movie of their childhood and create memories which will
					last them a lifetime. This life-long learning begins at our very own castle of love which is a wonderland with
					child-friendly infrastructure and facilities that provides the little ones, their second home!
				</p>

				<h3>SOUND FOUNDATION FOR LIFE</h3>
				<p>
					No one understands children like we do. We certainly know that the formative years of a child’s life are of utmost
					importance for development, which is why at Mine Kids, our child-centric approach ensures the perfect holistic
					development at this tender age.
				</p>

				<h3>CURRICULUM</h3>
				<p>
					The child psychology is ever changing and it is crucial to constantly monitor their changes & development process, so as
					to create a curriculum that is holistic and balanced. A perfect blend of educational exercises such as reading, writing
					and theme based learning along exciting co-curricular activities like art, craft, stage exposure & sports will
					definitely meet the developmental needs of toddlers. Our team of curriculum developers, have designed a scientific
					curriculum that gives opportunities to teachers to execute innovative ideas and ensure effective teaching keeping in
					mind the overall development of the children. The books have been curated by our in-house team of experts that are not
					only easy to follow but perfect captivate a child’s mind.
				</p>

				<h3>HOLISTIC DEVELOPMENT</h3>
				<p>
					Here, our children learn, discover, imagine and create under 5 domains of development: <br />
					<b>
						Socio-Emotional Development, Intellectual Development, Physical Development, Language Development, Creative
						Development
					</b>
				</p>

				<h3>CONFIDENCE BUILDING</h3>
				<p>
					A major part of the development process includes instilling confidence & self-esteem in our kids through diverse
					activities & events. <br />
					<b>Stage Exposure, Fashion Show , Fancy Dress & Roleplay, Class Presentations, Appreciation Day</b>
				</p>

				<h3>EVENTS & CELEBRATIONS</h3>
				<p>
					When learning can be so much fun, imagine how it would be when every day would turn into a celebration! <br />
					<b>National days & Festivals, Grandparents Day, Birthday Bashes</b>
				</p>

				<h3>INSTILLING MORAL VALUES</h3>
				<p>
					Education is incomplete if you don’t instill the values of life during the early years of a child. We raise our children
					to become model citizens who uphold impeccable moral values.
				</p>

				<h3>POSITIVE PARENTING</h3>
				<p>
					When a child attends Mine Kids, so does the whole family. Parents truly benefit from parenting seminars and tips on
					parenting skills. Let’s just say, we give our children, better parents too.
				</p>

				<h3>TEACHER’S TRAINING</h3>
				<p>
					At Mine Kids, various trainings and workshops for teachers are conducted to give them exposure so that they can lay a
					sound foundation for the little ones as well as add their special touch of love to blossom them into wonderful beings.
					The helper staff & drivers are also groomed to take good care of the Kids.
				</p>

				<h3>SAFETY & SECURITY</h3>
				<p>
					Safety & security is an area that shouldn't worry you at all when your child is at Mine Kids. Here, entry-exit slips,
					alertness of staff, CCTV Surveillance at common areas and our security incharges put all your anxiety to peace.
				</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>How?</h2>
				</div>
				<h3>ADMISSION PROCESS</h3>
				<ul>
					<li>The parents may visit our nearest branch and meet the counsellors to fill up an admission form.</li>
					<li>
						They may even take a school round and understand how little toddlers are nurtured beautifully with love and care.
					</li>
					<li>You can also fill the form online and our team will get back to you in no time</li>
				</ul>

				<h3>SPECIAL BENEFITS</h3>
				<p>Certain privileges are offered on the fee available across branches in the following categories:</p>
				<ul>
					<li>
						<b>APEX START :</b> By registering your child between 0-1 years of age & joining at 2, you get to save Rs. 1000/- to
						Rs. 1350/- on the monthly fee. Not only this, any time-related inflation or increase in the fee will not affect you at
						all.
					</li>
					<li>
						<b>Upon registration,</b> the child and parents become members of the Mine Kids family and are thereafter invited for
						all the events and orientations related to parenting and importance of Pre-School education, even before their child
						actually joins the school.
					</li>
					<li>
						<b>CORPORATE DISCOUNT:</b> We offer a discount of up to 30% on monthly fee to parents who seek admission of their
						child in the school through the corporate channel.
					</li>
					<li>
						<b>SIBLING:</b> All real siblings enjoy a special privilege on the regular fee structure.
					</li>
					<li>
						<b>NOBLE PROFESSION:</b> Teachers, doctors & defence personnel are honoured with a special discount in this category.
					</li>
				</ul>
			</div>

			{/* call us */}
			<div className={`${styles.Container} ${styles.Border}`}>
				<div className={styles.FaqHeading}>ENROLL YOUR CHILD TODAY</div>
				<h5>Give your child the exposure and opportunities to explore, learn and enjoy his childhood.</h5>

				<h6>
					Call at <em> 8917327419</em>
				</h6>
			</div>

			<div className={styles.FaqHeading}>Admission FAQ</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>About mine kids & curium</h2>
				</div>
				<h3>Why should I choose Mine Kids for my child?</h3>
				<p>
					At Mine Kids, love is the basis of our philosophy and here a child learns to start and enjoy learning with tender,
					loving care. We aim to blossom a child's personality by making the most of the formative years in an ideal learning
					environment. We prepare our children for life by imparting not only age-appropriate knowledge but also enhancing their
					talents and empowering them with life skills to ensure holistic development of the child that includes:
				</p>
				<ul>
					<li>Physical Development</li>
					<li>Intellectual Development</li>
					<li>Language Development </li>
					<li>Socio-Emotional Development</li>
					<li>Creative Development</li>
				</ul>
				<h3>Why should I not send my child directly to formal school?</h3>
				<p>
					Research shows that up to 50% of a child's ability to learn develops by the age of 5. Proper preschool educational
					ensures an environment for this growth to take place. According to the US Department Of Education, preschool plays a
					large role in later academic success. Children in high quality preschools display better language, cognitive, and social
					skills than children who attended low quality programs. They have longer attention spans, stronger social abilities, and
					better language and math skills well into their elementary school careers, and are better performers in college as well
					as life.
				</p>
				<h3>What is the right age to send a child to preschool?</h3>
				<p>
					Most experts call the child's 2nd year the miracle year. He/she can demonstrate amazing physical skills, think
					logically, manage simple tasks independently, speak few words or short sentences & happily stay apart from you for
					longer periods, etc. It is NOW that he/she gets the right stimulations and starts responding. The RIGHT ENVIRONMENT
					helps in developing the child's ability to learn, communicate, trust, love, and instills confidence and a positive
					self-esteem.
				</p>
				<h3>What is the curriculum that Mine Kids follows in playgroup and nursery?</h3>
				<p>
					Children at Mine Kids are groomed under the Quality Education System designed by the school's Research & Development
					department. This team of professionals plans a scientific curriculum, design innovative, effective teaching methods, and
					explore and create new and better teaching aids.
				</p>
				<h3>Does my child need to be toilet trained before he joins the preschool?</h3>
				<p>
					The school imparts toilet training by following a routine wherein a child is taken to the washroom when he reaches the
					school, before mealtime, after mealtime and when he is leaving in afternoon. Slowly and gradually the child falls into
					this pattern and starts informing the teachers and maids as and when he feels the urge.
				</p>
				<h3>Should I send him to preschool if he doesn't speak yet?</h3>
				<p>
					Our stage activities from the beginning enhance the language skills and confidence levels of each and every child. Not
					only this, all children conquer stage fright, and are trained to make the whole world a stage.
				</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>About Manpower</h2>
				</div>
				<h3>What is the quality/background of teachers that we have? Do they speak well in English?</h3>
				<p>
					Experienced and loving teachers are our biggest strength. The carefully selected staff is trained regularly about latest
					developments on early childhood education, child psychology, health and nutrition by a panel of experts. Besides
					possessing good communication skills, they are also specially trained to groom little children into leaders of tomorrow.
				</p>
				<h3>What about the class size and the student-teacher ratio that we follow?</h3>
				<p>Class size is between 15 and 20 and student teacher ratio is 10:1.</p>
				<h3>What is the student-maid ratio that you have?</h3>
				<p>
					Each class has a dedicated maid to help two teachers of the class. The washrooms across all floors have separate maids
					to assist the children with washing and cleaning.
				</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>About Facilities</h2>
				</div>
				<h3>What are the facilities that are unique to Mine Kids?</h3>
				<p>
					Our unique facilities include nature friendly environment, preparation of nutritious meals, safe and comfortable
					transport system, settling zone, exclusive day boarding facility, splash pool, imported teaching aids, equipment, and
					many more.
				</p>
				<h3>What are the activities that are unique to Mine Kids?</h3>
				<p>
					Our exclusive activities for children include dramatization and role-plays, educational trips, picnics and excursions,
					puppet show, story telling, sand play, water play, nature walk, structured intellectual and language activities, etc.
				</p>
				<h3>Do you teach swimming to the children here?</h3>
				<p>Splash pool is just for fun. Children at this age are too young, so we can't afford to take a chance with them.</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>About Fees</h2>
				</div>
				<h3>Why is Mine Kids more expensive than other play schools of its locality?</h3>
				<p>
					The answer to this is our unparalleled quality. To ensure that every child receives holistic early education, and enjoys
					every bit of his/her childhood, we provide them with the facilities that no other playschool has. With so much in store
					for your child, a little difference in fee with other playschools is worth it.
				</p>
				<h3>Do you have a Creche? If yes what are its facilities/ timings & charges?</h3>
				<p>
					Yes, day boarding facility is available in every branches of Mine Kids. The timings are from 12:30 pm to 5 pm ( Timings
					do vary between branches. Kindly discuss same with the Branch Personnel's).
				</p>
				<div className={styles.Para}>
					<p>The day boarding fee is as follows:</p>
					<p>Registration Fee (one time): Rs 1000 (approx)</p>
					<p>Monthly Charge: Rs 4000 - Rs 5000 (approx) </p>
					<p>depending upon the hours (It may vary between branches)</p>
				</div>
				<h3>What do annual charges include?</h3>
				<p>Events, picnics, excursions, celebrations, activities, etc.</p>
			</div>

			<div className={styles.Container}>
				<div className={styles.Heading}>
					<h2>Miscellaneous</h2>
				</div>
				<h3>When does the session start in Mine Kids?</h3>
				<p>
					As lot of children turn 2 in every month, we form Play Groups each month. Keeping in mind the date of birth &
					corresponding eligibility for formal school, the New Joinee children are assigned a class.
				</p>
				<h3>Do you have any tie up with any formal school?</h3>
				<p>
					In the history of Mine Kids it has not happened yet that our children have not got selected anywhere. In fact, a lot of
					formal schools very proudly announce that many of their children have come from the elite families at Mine Kids. But few
					of the upcoming branded formal school had completed the tie up process with us to secure few seats for Std I.
				</p>
			</div>
		</div>
	);
};

export default AdmissionFAQ;
