import React from "react";
import A1 from "../../assets/images/A1.png";
import A11 from "../../assets/images/A11.png";
import A12 from "../../assets/images/A12.png";
import A13 from "../../assets/images/A13.png";
import A14 from "../../assets/images/A14.png";
import A15 from "../../assets/images/A15.png";
import A16 from "../../assets/images/A16.png";
import A17 from "../../assets/images/A17.png";
import A10 from "../../assets/images/A10.png";
import A2 from "../../assets/images/A2.png";
import A3 from "../../assets/images/A3.png";
import A4 from "../../assets/images/A4.png";
import A5 from "../../assets/images/A5.png";
import A6 from "../../assets/images/A6.png";
import A7 from "../../assets/images/A7.png";
import A8 from "../../assets/images/A8.png";
import A9 from "../../assets/images/A9.png";
import SetTitle from "../../components/SetTitle";
import styles from "./Activities.module.scss";

const Activities = () => {
	SetTitle("Activities");

	return (
		<div className={styles.Activities}>
			<h1>Our Activities</h1>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Class presentation</h2>
					<span></span>
					<p>
						The class presentations are an event that we at Mine Kids look forward to every few months! This event is a quarterly
						culmination of the learnings of our children wherein they showcase their skills in diverse fields such as dance,
						music, recitation and yoga. At our class presentations, you can easily witness how well your little one is performing
						in school and be proud of his/her growth process. When the children get on the stage, they are simply magical. Their
						unmatched cuteness and confidence definitely deserve the glorious spotlight! While in the process, they also develop
						self-esteem and self-expression in the most unique way.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A1} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A2} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Fancy Dress</h2>
					<span></span>
					<p>
						Little children are much more capable than we can ever imagine. They can rouse your conscience on sensitive issues
						like no one can. Such are the soul stirring performances by the children at the Fancy Dress shows where they come
						wonderfully dressed up and talk about global warming, child labour, save the girl child and other socially relevant
						topics. These topics help increase their knowledge and make them socially responsible citizens. Their dynamic stage
						presence, vibrant costumes and charming personalities speak volumes of their confidence at such a tender age.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Fashion Show</h2>
					<span></span>
					<p>
						Playing dress up begins during the first five years and never really ends. â€˜Lights, Camera and Action!â€™. Here come
						the dazzling divas and dashing rock stars who will simply set the stage on fire at the very own Fashion Show of Mine
						Kids. Their stunning outfits, attractive personalities and confident walks are a true testimony to how they are
						trendsetters in the making! With their cute speeches they turn a lot of heads and stun the onlookers with their
						flawless delivery and dynamic stage presence. Wait for it! In a few years, all the best actors, models, leaders &
						artisans will be among the alumni of Mine Kids.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A3} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A4} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Story telling session</h2>
					<span></span>
					<p>
						Children have an innate love for stories. They have the power to create magic and a sense of adventure in the
						classroom. Stories are a great way to teach children about life. At Mine Kids, storytelling is a unique way for
						children to develop an understanding about the world around them. It creates interest among them as the character
						comes to life in front of them and captures their attention & imagination. Children learn about topics like good
						social habits, traffic rules and sharing through stories. They also develop language and imaginary skills as they
						narrate and listen to stories.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Intellectual Development Session</h2>
					<span></span>
					<p>
						Although, there is no big manual for mental development of kids, there certainly are great fun activities and amazing
						teacher! At Mine Kids, through meticulously designed intellectual development sessions children learn about various
						concepts. Designed around a child-centric approach the children are engaged in intellectual activities like solving
						puzzles and building towers to develop memory and observation skills, problem solving, sequencing and seriation. When
						they slide, flip, and turn things, they develop hand-eye coordination, analytical and fine motor skills in an
						interesting manner.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A5} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A6} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Rhymes and Songs</h2>
					<span></span>
					<p>
						Language development is yet another focus area for us and to achieve this, we teach the little ones through rhymes and
						songs. They are a great way to help anyone remember words and concepts. Using different rhyming words helps children
						develop an ear for phonics, thus, making it easy for them to create words by combining sounds. Teaching them rhymes &
						songs also helps them become better readers in future. Our little darlings look like a delight when they imitate the
						teachers by making funny faces and movements to match the words.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Circle Time</h2>
					<span></span>
					<p>
						Circle time is the time when friendship blossoms as children interact, socialize and share their thoughts and
						experiences with each other. Various activities are designed, creating opportunities for kids to experience the joy of
						talking and communicating with each other. On a regular day, the teacher and children gather together in a circle to
						create a rich weave of rhymes, games and songs. Developing social, emotional, and personal skills are some of the
						primary objectives of the Circle Time. The activity also fosters problem solving skills, language building and
						confidence as children are encouraged to express their views freely.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A7} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A8} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Role play</h2>
					<span></span>
					<p>
						There is no substitute for creative and imaginative play when it comes to teaching. Every child is born with an
						imagination. Allowing children to exercise their imagination enhances their creativity and creates better social
						understanding. This is the strength of children - their ability to pretend and fix problems with their imagination. To
						create a better understanding of various concepts, children are engaged in role plays. They become doctors,
						politicians and other community helpers and enact their lives. Through this activity, they develop love, respect, and
						appreciation for the world around them.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>puppet play</h2>
					<span></span>
					<p>
						Children love to use their imaginations to move beyond the bounds of reality. They can turn a stick into a magic wand,
						a sock into a puppet and themselves into superheroes. Puppets are toys that truly fascinate children; they connect as
						friends. Itâ€™s also a great way to draw the attention and imagination of preschoolers and while offering amazing
						benefits that aid a childâ€™s development. By organising Puppet Plays, we strive to bring stories to life, and
						inculcate various concepts like good habits, sharing, caring, etc. It also helps the kids develop vocabulary and
						improve communication & listening skills.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A9} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A10} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Drawing & art</h2>
					<span></span>
					<p>
						Every child has a creative world inside him. We help our children explore that world through art and craft activities.
						They simply love playing with textures and materials as well as putting together and taking apart objects to create
						something unique, something new. It provides a new set of skills for self-expression, communication and endless
						amazement for the children. Craft work enhances their creativity, contributes to the pre-writing skills and develops
						grasping power among the children as they hold colours, cut patterns and draw shapes.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Yoga</h2>
					<span></span>
					<p>
						Yoga provides an ideal channel for these high-energy youngsters to build a foundation for a healthy development of
						mind & body. It increases coordination, improves concentration & self-expression and encourages a calmer mind. You’ll
						simply laugh out loud when you’ll see our little darlings try out yoga poses. Ask them to close their eyes and they
						will still manage to take a peep! But, when they truly indulge in their Yoga sessions, they develop mental discipline
						and agility. So, let’s get started! Om Shanti!
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A11} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A12} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Free-play</h2>
					<span></span>
					<p>
						Structured activities are very important for fostering development among the children. The routines and rules help
						them become disciplined and focus on enhancing many other life skills. However, introducing time for free play can
						make their day more balanced. During free-play, a child can enjoy and learn at his/her own pace. It builds the kind of
						‘free & easy’, ‘try it out’, ‘do it yourself’ type of culture that our future needs. We simply love the sight when the
						Kids have a gala time at their favourite play area. The swings, slides, see-saw and little monkey bars also fosters
						physical development, enables independence and enhances their decision making, collaborative play & gross motors
						skills.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Ball Pool</h2>
					<span></span>
					<p>
						There are certain activities that are very close your heart during childhood. One of them being spending countless
						hours at the ball pool. We couldn’t differ with this feeling, which is why, we take our children to our very own ball
						pool where they enjoy playing around many colourful balls. The ball pool provides a multi-sensory environment wherein
						the children play, learn and relax. It is also believed that ball pools have a therapeutic effect on them. The visual
						and tactile sensory stimuli strengthen their gross motor skills. They also get to learn about colours and shapes in a
						fun way.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A13} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A14} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Splash Pool</h2>
					<span></span>
					<p>
						<b>Who doesn’t enjoy being cool by the pool?</b>
						<br /> When the temperatures soar in summer, to beat the heat, our Kids take a dip in the pool of love. Never would
						you witness pool parties any cuter than the ones at Mine Kids Dressed in colourful swimming costumes, the Kids
						splish-splash in the water, enjoy a rain dance under a huge fountain and have a delightful time with their peers.
						Other special arrangements are also made to make their experience more delightful such as slides, colourful balls,
						toys and sand play. This activity is yet another way to develop the social skills of our toddlers.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Picnics</h2>
					<span></span>
					<p>
						Running crazily all over the ground, chasing butterflies, giggling with friends, the little darlings of our school get
						to live every moment of their childhood at fun-filled picnics. They spend the day at various parks such as the
						Children’s Park, Nandankanan Zoo, etc and develop peer group bonding while learning to behave in a socially acceptable
						manner. There need not be a special occasion to get your loved ones together; picnics are a great way to spend quality
						time and creating lasting memories of togetherness in such a pleasant environment.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A15} alt="" />
				</div>
			</div>

			<div className={styles.ContainerRight}>
				<div className={styles.Left}>
					<img src={A16} alt="" />
				</div>
				<div className={styles.Right}>
					<h2>Nature Walks</h2>
					<span></span>
					<p>
						In every walk with nature, one receives far more than he seeks! It is believed that walking around the nature can
						reduce stress levels in children by 28%. To let nature be the teacher of our Kids, we take them for nature walks to
						nearby gardens. During their walk, they not only build the ability to observe and explore the world around them, but
						also hone socio-emotional skills by coming in direct contact with their surroundings. Overall, it’s a fun-filled day
						that stimulates curiosity, instils love for learning and creates a long-lasting connection with the nature.
					</p>
				</div>
			</div>

			<div className={styles.ContainerLeft}>
				<div className={styles.Left}>
					<h2>Camps</h2>
					<span></span>
					<p>
						Organizing summer and winter camps at Mine Kids Preschool can provide children with enriching experiences while also
						offering parents reliable care options during school breaks.
					</p>
				</div>
				<div className={styles.Right}>
					<img src={A17} alt="" />
				</div>
			</div>
		</div>
	);
};

export default Activities;
